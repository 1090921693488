import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faMessage,
  faRightFromBracket,
  faRightToBracket,
  faUser,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";

export default function LoginMenu({ navSmall, setNavSmall, isLogged, setIsLogged }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const navigate = useNavigate();
  const [size, setSize] = useState({ min: false, mid: true, full: false });

  const logout = () => {
    setIsLogged(false);
    localStorage.removeItem("userData");
    localStorage.removeItem("rt");
    localStorage.removeItem("p");
  };

  const buttons = () => {
    return (
      <div className="w-full px-2 flex justify-center items-center flex-col gap-2">
        {!isLogged ? (
          <button
            disabled={true}
            onClick={() => navigate("/prihlasenie")}
            className={clsx("h-10 w-full bg-blue-200 rounded-lg", {
              // "w-full": isSmallDevice || !size.full,
              // "w-1/2": !isSmallDevice && size.full,
            })}
          >
            <FontAwesomeIcon icon={faRightToBracket} /> Prihlásenie
          </button>
        ) : null}
        {isLogged ? (
          <>
            <button
              onClick={() => navigate("/sprava-uctu/profil")}
              className={clsx("h-10 w-full bg-green-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <FontAwesomeIcon icon={faUser} /> Dashboard
            </button>
            <button
              onClick={() => navigate("/spravy")}
              className={clsx("h-10 w-full bg-purple-200 rounded-lg", {
                // "w-full": isSmallDevice || !size.full,
                // "w-1/2": !isSmallDevice && size.full,
              })}
            >
              <FontAwesomeIcon icon={faMessage} /> Správy
            </button>
          </>
        ) : null}

        {isLogged ? (
          <button
            onClick={() => {
              logout();
            }}
            className={clsx("h-10 w-full bg-cyan-200 rounded-lg", {
              // "w-full": isSmallDevice || !size.full,
              // "w-1/2": !isSmallDevice && size.full,
            })}
          >
            <FontAwesomeIcon icon={faRightFromBracket} /> Odhlásiť
          </button>
        ) : null}
      </div>
    );
  };

  const changeSize = () => {
    if (size.mid) {
      setSize({ ...size, mid: false, full: true, min: false });
    } else {
      setSize({ ...size, mid: true, full: false, min: false });
    }
  };

  const minimize = () => {
    if (size.mid) {
      setSize({ ...size, min: true, mid: false, full: true });
    }
    if (size.full) {
      setSize({ ...size, min: true, mid: true, full: false });
    }
  };

  return (
    <AnimatePresence>
      {navSmall.login ? ( //&& isSmallDevice
        <motion.div
          className={clsx(
            "transition-all duration-200 ease-linear overflow-auto absolute h-auto bg-sky-100 flex flex-col items-center gap-2 no-scrollbar select-none",
            {
              "!h-[calc(100%-64px)] !rounded-none": size.full && !size.min && isSmallDevice,
              "!h-[30%]": size.mid && !size.min && isSmallDevice,
              "!h-[10%]": size.min && isSmallDevice,
              "bottom-0 left-0 w-full pb-5 rounded-t-xl": isSmallDevice,
              "top-0 left-16 h-full": !isSmallDevice,
              "w-96": !isSmallDevice && size.mid,
              "w-[calc(100%-64px)]": !isSmallDevice && size.full,
            },
          )}
          initial={{ opacity: 0, scale: 1, y: isSmallDevice ? 300 : 0, x: !isSmallDevice ? -500 : 0 }}
          animate={{ opacity: 1, scale: 1, y: isSmallDevice ? 0 : 0, x: !isSmallDevice ? 0 : 0 }}
          exit={{ opacity: 1, scale: 1, y: isSmallDevice ? 350 : 0, x: !isSmallDevice ? -500 : 0 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div
            className={clsx(
              "sticky top-0 right-0 rounded-t-lg w-full h-8 bg-sky-100 border-b border-opacity-50 border-b-sky-500 z-10 grid",
              {
                "grid-cols-[10%_10%_50%_10%_10%_10%]": isSmallDevice,
                "grid-cols-[10%_10%_60%_10%_10%]": !isSmallDevice, //&& !size.full,
                // "grid-cols-[10%_10%_35%_10%_10%_25%]": !isSmallDevice && size.full,
              },
            )}
          >
            <button disabled={true} className="w-full h-full disabled:text-gray-300">
              <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-4" />
            </button>
            <button
              disabled={true}
              className="w-full h-full border-x border-opacity-50 border-x-sky-500 disabled:text-gray-300"
            >
              <FontAwesomeIcon icon={faChevronRight} className="w-5 h-4" />
            </button>
            <div className="w-full h-full flex justify-start items-center px-1">Prihlásenie</div>
            {isSmallDevice ? (
              <button onClick={() => minimize()} className="w-full h-full border-l border-opacity-50 border-l-sky-500">
                <FontAwesomeIcon icon={faWindowMinimize} />
              </button>
            ) : null}
            <button onClick={() => changeSize()} className="w-full h-full border-x border-opacity-50 border-x-sky-500">
              {size.full ? <FontAwesomeIcon icon={faWindowRestore} /> : null}
              {size.mid ? <FontAwesomeIcon icon={faWindowMaximize} /> : null}
            </button>
            <button onClick={() => setNavSmall({ ...navSmall, login: false })} className="w-full h-full">
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>
          {buttons()}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
