import { useState, useEffect } from "react";
import { categoryService } from "../../services/categoryService";
import { averagePriceByCodeService } from "../../services/averagePriceByCodeService";
import Municipalities from "./Municipalities";
import Message from "../Message";
import UsableArea from "./UsableArea";
import SelectStatus from "./Status";
import Category from "./Category";
import LandArea from "./LandArea";
import { categoryList } from "../../data/categoryList";

export default function NewAveragePrice() {
  const [isOpen, setIsOpen] = useState(false);

  const [categoryValue, setCategoryValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [muniValue, setMuniValue] = useState("");
  const [muniValue2, setMuniValue2] = useState("");
  const [usableArea, setUsableArea] = useState(15);
  const [landArea, setLandArea] = useState(150);
  const [statuses, setStatuses] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [averagePrice, setAveragePrice] = useState([]);

  const [minUA, setMinUA] = useState(15);
  const [maxUA, setMaxUA] = useState(20);
  // eslint-disable-next-line
  const [minLA, setMinLA] = useState(150);
  // eslint-disable-next-line
  const [maxLA, setMaxLA] = useState(15000);

  useEffect(() => {
    getAllStatuses();
    getAllMunicipalities();
  }, []);

  const getAllStatuses = async () => {
    const response = await categoryService.getAllStatuses();
    const data = await response.json();
    setStatuses(data);
  };

  const getAllMunicipalities = async () => {
    const response = await categoryService.getAllMunicipalities();
    const data = await response.json();
    setMunicipalities(data);
  };

  function changeMuniStatus(e) {
    setMuniValue2(e);
    setMuniValue(municipalities.filter((name) => name.name === e).map((code) => code.code));
  }

  function getAvgPrice() {
    averagePriceByCodeService
      .getAveragePrice(categoryValue, statusValue, usableArea, landArea, muniValue)
      .then((response) => response.json())
      .then((data) => {
        setAveragePrice(data);
        setIsOpen(true);
      });
  }

  return (
    <div>
      <div className="absolute min-h-screen w-full flex flex-col items-center backdrop-blur-md pt-20">
        {!isOpen ? (
          <div className="bg-opacity-50 bg-sky-500 rounded-2xl p-3">
            <Category
              catList={categoryList}
              catVal={categoryValue}
              changeCategory={setCategoryValue}
              getMin={setMinUA}
              getMax={setMaxUA}
              uArea={setUsableArea}
              changeIsOpen={setIsOpen}
              resetAvgPrice={setAveragePrice}
            />
            <Municipalities
              options={municipalities}
              name="name"
              code="code"
              selectedVal={muniValue2}
              handleChange={(code) => changeMuniStatus(code)}
              district="externalDistrictName"
              changeIsOpen={setIsOpen}
              resetAvgPrice={setAveragePrice}
            />
            <SelectStatus
              catVal={categoryValue}
              statVal={statusValue}
              changeStatus={setStatusValue}
              statuses={statuses}
              changeIsOpen={setIsOpen}
              resetAvgPrice={setAveragePrice}
            />
            <UsableArea
              catVal={categoryValue}
              changeArea={setUsableArea}
              uArea={usableArea}
              min={minUA}
              max={maxUA}
              changeIsOpen={setIsOpen}
              resetAvgPrice={setAveragePrice}
            />
            <LandArea
              catVal={categoryValue}
              changeArea={setLandArea}
              landArea={landArea}
              min={minLA}
              max={maxLA}
              changeIsOpen={setIsOpen}
              resetAvgPrice={setAveragePrice}
            />
            <button
              disabled={
                categoryValue === "" ||
                categoryValue === null ||
                muniValue2 === "" ||
                muniValue2 === null ||
                (Number(usableArea) < Number(minUA) && usableArea !== "") ||
                (Number(usableArea) > Number(maxUA) && usableArea !== "") ||
                (Number(landArea) < Number(minLA) && landArea !== "") ||
                (Number(landArea) > Number(maxLA) && landArea !== "")
              }
              title="Vypočítať cenu nehnuteľnosti "
              className={`disabled:opacity-60 disabled:bg-slate-500 mb-4 bg-sky-500 rounded-2xl ml-2 hover:opacity-75 text-white py-2 px-4 ${
                categoryValue === "" ? "hidden" : ""
              }`}
              type="button"
              onClick={getAvgPrice}
            >
              Zisti cenu
            </button>
          </div>
        ) : (
          <Message
            Open={isOpen}
            averagePrice={averagePrice}
            changeIsOpen={setIsOpen}
            changeArea={setLandArea}
            changeCategory={setCategoryValue}
            changeStatus={setStatusValue}
            handleChange={(code) => changeMuniStatus(code)}
          />
        )}
      </div>
    </div>
  );
}
