import { Fragment, useRef } from "react";
import { useMediaQuery } from "usehooks-ts";
import { InfoWindow, Marker } from "@react-google-maps/api";
import ForSalePinSingle from "../../Images/ForSalePinSingle.svg";
import historyLight from "../../Images/Pins/historyLight.png";
import historyDark from "../../Images/Pins/historyDark.png";
import historyFlatLight from "../../Images/Pins/historyFlatLight.png";
import historyFlatDark from "../../Images/Pins/historyFlatDark.png";

export default function HistoryPins({
  visibilityOfMarkers,
  historyItems,
  map,
  view,
  mouseOver,
  setMouseOver,
  previewH,
  indx,
  setCurrentIndex,
  setVisibilityOfMarkers,
  setSubMenu,
  subMenu,
  setHistoryMenu,
  zoom,
  highlight,
  navSmall,
  setNavSmall,
  hideMarkers,
  setHistory,
}) {
  const druhStavby = useRef("");
  const historyItem = useRef("");
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  function icon(index) {
    if (historyItems[index].response?.List?.length !== undefined) {
      historyItem.current = historyItems[index].response?.List[0];
      if (historyItems[index].response?.List[0]?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby[0]?.druh_stavby;
      }
    } else {
      historyItem.current = historyItems[index].response?.List;
      // console.log("druh stavby = ", historyItems[index].response?.List?.table_stavby?.length);
      if (historyItems[index].response?.List?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List?.table_stavby[0]?.druh_stavby;
      }
    }

    if (historyItem.current?.hasOwnProperty("table_stavby")) {
      if (druhStavby.current === "9") {
        if (highlight.history && highlight.indexH === index) {
          return historyFlatDark;
        } else {
          return historyFlatLight;
        }
      } else {
        if (highlight.history && highlight.indexH === index) {
          return historyDark;
        } else {
          return historyLight;
        }
      }
    } else {
      return historyLight;
    }
  }

  const showDetail = (index) => {
    // map.panTo({ lat: historyItem.latAndLng.lat, lng: historyItem.latAndLng.lng });
    if (window.location.pathname.includes("share")) {
      window.history.replaceState(null, "", "/");
    }
    setCurrentIndex(index);
    setVisibilityOfMarkers("singleHistoryPin");
    setSubMenu({
      ...subMenu,
      lv: true,
      interest: false,
      owner: false,
      rating: false,
      share: false,
    });
    indx.current = index;
    setHistoryMenu(true);
    setHistory(true);
    setNavSmall({
      ...navSmall,
      properties: true,
      subjects: false,
      helper: false,
      options: false,
      login: false,
    });
  };
  return ((visibilityOfMarkers === "historyOpened" || visibilityOfMarkers === "singleHistoryPin" || zoom > 1) &&
    !hideMarkers.history) ||
    (hideMarkers.history && (visibilityOfMarkers === "historyOpened" || visibilityOfMarkers === "singleHistoryPin")) ? (
    <>
      {historyItems.map((historyItem, index) => (
        // <Fragment key={uuidv4()}>
        <Marker
          key={index}
          onClick={() => {
            if (isSmallDevice) {
              showDetail(index);
            }
          }}
          onMouseOver={() => previewH(index, historyItem)}
          // onMouseOut={() => setMouseOver({ ...mouseOver, previewH: false, indexH: null })}
          position={{ lat: historyItem.latAndLng.lat, lng: historyItem.latAndLng.lng }}
          label={{
            text: zoom > 12 ? historyItem?.avgPrice?.avgPrice?.toString() + " €" : " ",
            color: view === "hybrid" ? "#fff" : "#5491f5",
            fontSize: "14px",
            className: `absolute top-9 left-3 p-1 + ${view === "hybrid" ? "specialtext" : "specialtextWhite"}`,
          }}
          icon={{
            // url: highlight.history && highlight.index === index ? icon(index) : icon(index),
            url: icon(index),
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        >
          {mouseOver.previewH && mouseOver.indexH === index ? (
            <InfoWindow
              position={{
                lat: Number(historyItem?.latAndLng?.lat),
                lng: Number(historyItem?.latAndLng?.lng),
              }}
            >
              <div
                onClick={() => {
                  showDetail(index);
                }}
                onMouseLeave={() => setMouseOver({ ...mouseOver, previewH: false, indexH: null })}
                className="absolute bottom-1 w-60 h-52 z-50 cursor-pointer text-lg flex flex-col justify-start items-center rounded-lg"
              >
                <div className="w-60 h-40 bg-sky-100 z-50 text-lg flex flex-col justify-center items-center rounded-lg">
                  <div className="w-full h-1/2 rounded-t-lg">
                    <img src={historyItem?.response?.img} alt="" className="w-full h-full object-cover rounded-t-lg" />
                  </div>
                  <div className="w-full h-1/2 flex flex-col justify-start p-1 text-base">
                    <p className="font-medium ">
                      {historyItem?.location} {historyItem?.number}
                    </p>
                    <p>Trhová cena: {historyItem?.avgPrice?.avgPrice} €</p>
                  </div>
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
        // </Fragment>
      ))}
    </>
  ) : null;
}
