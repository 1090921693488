import { React } from "react";
import { Marker } from "@react-google-maps/api";
import pinsolid from "../../Images/pinsolid.svg";
import PropertyList from "../List/PropertyList";
import NewResponseMessageSmall from "./NewResponseMessageSmall";

export default function Pin({
  isClickedRef,
  latRef,
  longRef,
  address,
  center,
  avgPrice,
  setResponse,
  gotResponse,
  propList,
  setInfo,
  showInfo,
  setShowLV,
  showLV,
  setLvNumber,
  lvNumber,
  setBlockOfFlats,
  blockOfFlats,
  subMenu,
  setSubMenu,
  addressService,
  setHistoryItems,
  setVisibilityOfMarkers,
  setAvgPrice,
  avgPriceRef,
  municipalitiesRef,
  setPrepareHistory,
  prepareHistory,
  getNumProp,
}) {
  let porCislo = "";
  let serialNumberArray = "";

  const getSerialNumber = (porCislo) => {
    let split_string = porCislo?.split(".");
    serialNumberArray = split_string.filter((item) => item).map((x) => parseInt(x));
    return serialNumberArray;
  };

  const getTarchArray = (tarchy) => {
    const tarcha = JSON.stringify(tarchy.poradove_c_vlastnika_)
      ?.replaceAll('"', "")
      .split(" ")
      .filter((item) => item)
      .map((x) => parseInt(x));
    return tarcha;
  };

  function filterArray() {
    if (propList.List?.hasOwnProperty("byt")) {
      const filteredArray = {
        ...propList,
        List: {
          ...propList.List,
          byt: propList.List.byt.filter((item) => {
            const entranceFilter =
              blockOfFlats.entrance === null ||
              blockOfFlats.entrance ===
                (typeof blockOfFlats.entrance === "string" ? item.vchod__cislo_ : Number(item.vchod__cislo_));
            const floorFilter =
              blockOfFlats.floor === null ||
              blockOfFlats.floor === (typeof blockOfFlats.floor === "string" ? item.poschodie : Number(item.poschodie));
            // // const lvNumberFilter = (lvNumber === null || lvnumber)
            const flatFilter = blockOfFlats.flat === null || blockOfFlats.flat === Number(item.cislo_bytu);

            if (
              blockOfFlats.flat === Number(item.cislo_bytu) &&
              blockOfFlats.entrance === Number(item.vchod__cislo_) &&
              item.vlastnici instanceof Array
            ) {
              porCislo = item.vlastnici[0].por_c;
              console.log("vlastnici length = ", item?.vlastnici?.length);
              let porCisla = "";
              for (let i = 0; i < item?.vlastnici?.length; i++) {
                porCisla = porCisla + item.vlastnici[i].por_c;
              }
              getSerialNumber(porCisla);
            } else {
              porCislo =
                blockOfFlats.flat === Number(item.cislo_bytu) && blockOfFlats.entrance === Number(item.vchod__cislo_)
                  ? item.vlastnici.por_c
                  : null;
              if (porCislo !== null) {
                getSerialNumber(porCislo);
              }
            }
            return [entranceFilter, floorFilter, flatFilter].every((filter) => filter);
          }),
          tarchy: propList.List.tarchy?.filter((item) => {
            const tarcha = getTarchArray(item);
            let tarchaFilter = "";
            for (let i = 0; i < tarcha?.length; i++) {
              tarchaFilter = tarcha[i] === serialNumberArray[0] ? true : false;
              if (tarchaFilter === true) {
                return [tarchaFilter].every((filter) => filter);
              }
            }
            return [tarchaFilter].every((filter) => filter);
          }),
        },
      };
      return filteredArray;
    } else {
      return propList;
    }
  }

  return (
    <>
      {isClickedRef?.current === true ? (
        <>
          <div className="bg-black">
            <Marker
              onClick={() => setInfo(!showInfo)}
              position={{ lat: latRef.current, lng: longRef.current }}
              // draggable={true}
              // label={{ text: address, color: "#000", fontSize: "14px", className: "marker-label" }}
              icon={{ url: pinsolid, scaledSize: new window.google.maps.Size(50, 50) }}
            >
              <NewResponseMessageSmall
                gotResponse={gotResponse}
                setResponse={setResponse}
                avgPrice={avgPrice}
                propList={propList}
                address={address}
                subMenu={subMenu}
                setSubMenu={setSubMenu}
                lvNumber={lvNumber}
                setLvNumber={setLvNumber}
                filterArray={filterArray}
                setBlockOfFlats={setBlockOfFlats}
                blockOfFlats={blockOfFlats}
                addressService={addressService}
                setHistoryItems={setHistoryItems}
                setVisibilityOfMarkers={setVisibilityOfMarkers}
                setAvgPrice={setAvgPrice}
                avgPriceRef={avgPriceRef}
                municipalitiesRef={municipalitiesRef}
                setPrepareHistory={setPrepareHistory}
                prepareHistory={prepareHistory}
                getNumProp={getNumProp}
              />
            </Marker>
          </div>
        </>
      ) : (
        <div className="bg-black">
          <Marker
            onClick={() => setInfo(!showInfo)}
            position={{ lat: center.lat, lng: center.lng }}
            // draggable={true}
            // label={{ text: address, color: "#000", fontSize: "14px", className: "marker-label" }}
            icon={{ url: pinsolid, scaledSize: new window.google.maps.Size(50, 50) }}
          ></Marker>
        </div>
      )}
      {showLV ? (
        <div className="fixed left-0 top-0 overflow-scroll w-screen h-screen bg-sky-100 z-50">
          <PropertyList propList={filterArray()} showLV={showLV} setShowLV={setShowLV} lvNumber={lvNumber} />
        </div>
      ) : null}
    </>
  );
}
