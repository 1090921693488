import { useEffect, useState } from "react";
import Load from "../constants/Load";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import RHeader from "../ResponseParts/RHeader";
import RScrollMenu from "../ResponseParts/RScrollMenu";
import RInfo from "../ResponseParts/RInfo";
import Share from "../NewMenu.jsx/Share";
import Rating from "../NewMenu.jsx/Rating";
import Interest from "../NewMenu.jsx/Interest";
import RLv from "../ResponseParts/Lv/RLv";
import Owner from "../NewMenu.jsx/Owner";
import SelectLvNum from "../NewMenu.jsx/Select/SelectLvNum";
import SelectEntrance from "../NewMenu.jsx/Select/SelectEntrance";
import SelectFloor from "../NewMenu.jsx/Select/SelectFloor";
import SelectFlat from "../NewMenu.jsx/Select/SelectFlat";
import { useMediaQuery } from "usehooks-ts";

export default function NewResponseMessageSmall({
  gotResponse,
  setResponse,
  avgPrice,
  propList,
  address,
  subMenu,
  setSubMenu,
  lvNumber,
  setLvNumber,
  filterArray,
  blockOfFlats,
  setBlockOfFlats,
  addressService,
  setHistoryItems,
  setVisibilityOfMarkers,
  setAvgPrice,
  avgPriceRef,
  municipalitiesRef,
  setPrepareHistory,
  prepareHistory,
  getNumProp,
}) {
  const [priceExpanded, setPriceExpanded] = useState(false);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [previous, setPrevious] = useState("");
  const [rating, setRating] = useState({ awesome: false, good: false, meh: false, bad: false });
  const [size, setSize] = useState({ min: false, mid: true, full: false });
  const [inputValues, setInputValues] = useState({
    writeMessage: "",
    arrangeVisit: "",
    determinePrice: "",
    book: "",
    review: "",
    photos: [],
    price: "",
    area: "",
    condition: "",
    type: "",
    description: "",
    url: "",
  });
  const [lvSubMenu, setLvSubMenu] = useState({
    lv: false,
    partA: true,
    parcelC: true,
    stavby: true,
    tarchy: true,
    owner: true,
  });
  const [ownerMenu, setOwnerMenu] = useState({ sell: false });
  const [inputValue, setInputValue] = useState(0);
  useEffect(() => {
    filterArray();
  });

  const showMenu = (menu) => {
    if (subMenu.lv) setPrevious("lv");
    if (subMenu.interest) setPrevious("in");
    if (subMenu.owner) setPrevious("ow");
    if (subMenu.rating) setPrevious("ra");
    if (subMenu.share) setPrevious("sh");
    if (subMenu.disp) setPrevious("ds");

    if (menu === "lv") {
      setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, disp: false, rating: false, share: false });
    }
    if (menu === "in") {
      setSubMenu({ ...subMenu, lv: false, interest: true, owner: false, disp: false, rating: false, share: false });
    }
    if (menu === "ow") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, disp: false, rating: false, share: false });
    }
    if (menu === "ra") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, disp: false, rating: true, share: false });
    }
    if (menu === "sh") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, disp: false, rating: false, share: true });
    }
    if (menu === "ds") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, disp: true, rating: false, share: false });
    }
  };

  const changeSize = () => {
    if (size.mid) {
      setSize({ ...size, mid: false, full: true, min: false });
    } else {
      setSize({ ...size, mid: true, full: false, min: false });
    }
  };

  const minimize = () => {
    if (size.mid) {
      setSize({ ...size, min: true, mid: false, full: true });
    }
    if (size.full) {
      setSize({ ...size, min: true, mid: true, full: false });
    }
  };

  const goBack = () => {
    if (blockOfFlats.flat !== null) {
      setBlockOfFlats({ ...blockOfFlats, flat: null });
      // setFlat(null);
      return;
    }
    if (blockOfFlats.floor !== null) {
      setBlockOfFlats({ ...blockOfFlats, floor: null });
      // setFloor(null);
      return;
    }
    if (blockOfFlats.entrance !== null) {
      setBlockOfFlats({ ...blockOfFlats, entrance: null });
      // setEntrance(null);
      return;
    }
  };

  // console.log("avgPrice = ", avgPrice?.avgPrice);
  // console.log("propList = ", propList);

  const title = () => {
    const type = propList?.List?.hasOwnProperty("byt") ? "Byt" : propList?.List === undefined ? "" : "Rodinný dom";
    return `${type}`;
  };

  // console.log("filteredArray = ", filterArray());

  // const filteredArray = filterArray();

  //  const categoryType = () => {
  //    if (filteredArray.List.table_stavby.length !== undefined) {
  //      return filteredArray.List.table_stavby[0].druh_stavby;
  //    } else {
  //      return filteredArray.List.table_stavby.druh_stavby;
  //    }
  //  };

  return gotResponse === "true" || gotResponse === "loading" ? (
    <AnimatePresence>
      <motion.div
        className={clsx(
          "transition-all duration-200 ease-linear overflow-auto absolute bg-sky-100 flex flex-col items-center select-none z-50",
          {
            "!overflow-hidden !p-0": gotResponse !== "true",
            "!h-[calc(100%-64px)] !rounded-none": size.full && !size.min && gotResponse === "true" && isSmallDevice,
            "!h-[45%]": size.mid && !size.min && gotResponse === "true" && isSmallDevice,
            "!h-[10%]": size.min && isSmallDevice,
            "bottom-0 left-0 w-full": isSmallDevice,
            "top-0 left-16 !h-full": !isSmallDevice,
            "w-96": !isSmallDevice && size.mid,
            "w-[calc(100%-64px)]": !isSmallDevice && size.full,
          }, //overflow-visible
        )}
        initial={{ opacity: 0, scale: 1, y: isSmallDevice ? 300 : 0, x: !isSmallDevice ? -500 : 0, height: 60 }}
        animate={{
          opacity: 1,
          scale: 1,
          y: isSmallDevice ? 0 : 0,
          x: !isSmallDevice ? 0 : 0,
          height: gotResponse !== "true" ? 60 : window.innerHeight - 64,
        }}
        transition={{
          duration: 0.6,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        {gotResponse === "true" ? (
          <>
            <div
              onClick={() => {
                // setEndPositionY(0);
              }}
              className={clsx(
                "sticky top-0 right-0 rounded-t-lg w-full h-8 bg-sky-100 border-b border-opacity-50 border-b-sky-500 z-10 grid",
                {
                  "grid-cols-[10%_60%_10%_10%_10%]": isSmallDevice,
                  "grid-cols-[10%_70%_10%_10%]": !isSmallDevice,
                },
              )}
            >
              <button
                disabled={
                  blockOfFlats.flat === null && blockOfFlats.floor === null && blockOfFlats.entrance === null
                    ? true
                    : false
                } //&& entrance === null
                onClick={() => goBack()}
                className="w-full h-full disabled:text-gray-300 border-r border-opacity-50 border-r-sky-500"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-4" />
              </button>
              <div className="w-full h-full text-start pt-[2px] pl-1 overflow-hidden truncate">{title()}</div>
              {isSmallDevice ? (
                <button
                  onClick={() => minimize()}
                  className="w-full h-full border-l border-opacity-50 border-l-sky-500"
                >
                  <FontAwesomeIcon icon={faWindowMinimize} />
                </button>
              ) : null}
              <button
                onClick={() => changeSize()}
                className="w-full h-full border-x border-opacity-50 border-x-sky-500"
              >
                {size.full ? <FontAwesomeIcon icon={faWindowRestore} /> : null}
                {size.mid ? <FontAwesomeIcon icon={faWindowMaximize} /> : null}
              </button>
              <button
                onClick={() => {
                  setResponse(false);
                  setVisibilityOfMarkers("false");
                  setBlockOfFlats({ ...blockOfFlats, entrance: null, floor: null, flat: null });
                }}
                className="w-full h-full"
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className={clsx("w-full h-full pt-4", {})}>
              <RHeader address={address} propList={propList} blockOfFlats={blockOfFlats} />
              {propList?.List === undefined || propList.length === 0 ? (
                <div className="w-full flex justify-center items-center pt-5 font-semibold text-lg">
                  Nevieme vypočítať
                </div>
              ) : (
                <div className="w-full h-auto flex flex-col px-2">
                  <SelectLvNum propList={propList} lvNumber={lvNumber} setLvNumber={setLvNumber} />
                  <SelectEntrance propList={propList} blockOfFlats={blockOfFlats} setBlockOfFlats={setBlockOfFlats} />
                  <SelectFloor propList={propList} blockOfFlats={blockOfFlats} setBlockOfFlats={setBlockOfFlats} />
                  <SelectFlat
                    filteredArray={filterArray()}
                    blockOfFlats={blockOfFlats}
                    setBlockOfFlats={setBlockOfFlats}
                    addressService={addressService}
                    avgPrice={avgPrice}
                    setHistoryItems={setHistoryItems}
                    setAvgPrice={setAvgPrice}
                    avgPriceRef={avgPriceRef}
                    municipalitiesRef={municipalitiesRef}
                    setPrepareHistory={setPrepareHistory}
                    prepareHistory={prepareHistory}
                  />
                  {!propList?.List?.hasOwnProperty("byt") && avgPrice?.avgPrice === undefined ? (
                    <div className="flex flex-col gap-1 mt-1">
                      <p>Úžitková plocha m²: </p>
                      <input
                        className="w-full h-10 border-2 border-red-500 rounded-2xl"
                        placeholder="100"
                        type="number"
                        onChange={(e) => setInputValue(e.target.value)}
                      ></input>
                      <button
                        className="w-auto h-10 bg-sky-300 rounded-2xl px-1 disabled:bg-gray-200"
                        onClick={() => getNumProp(propList, inputValue)}
                        disabled={inputValue === 0 || inputValue === ""}
                      >
                        Vypočítať
                      </button>
                    </div>
                  ) : null}

                  {(!propList.List?.hasOwnProperty("byt") && propList?.List instanceof Array && lvNumber !== null) ||
                  (propList.List?.hasOwnProperty("byt") &&
                    blockOfFlats.floor !== null &&
                    blockOfFlats.entrance !== null &&
                    blockOfFlats.flat !== null) ||
                  (!propList.List?.hasOwnProperty("byt") && !(propList?.List instanceof Array)) ? (
                    <>
                      {avgPrice?.avgPrice !== undefined ? (
                        <>
                          <RScrollMenu showMenu={showMenu} subMenu={subMenu} size={size} />
                          <RInfo subMenu={subMenu} avgPrice={avgPrice} previous={previous} />
                          <Share subMenu={subMenu} active={"none"} />
                          <Rating
                            subMenu={subMenu}
                            setRating={setRating}
                            rating={rating}
                            previous={previous}
                            inputValues={inputValues}
                            setInputValues={setInputValues}
                          />
                          <Interest
                            subMenu={subMenu}
                            inputValues={inputValues}
                            setInputValues={setInputValues}
                            previous={previous}
                          />
                          <RLv
                            subMenu={subMenu}
                            previous={previous}
                            lvSubMenu={lvSubMenu}
                            setLvSubMenu={setLvSubMenu}
                            propList={
                              filterArray()?.List instanceof Array ? filterArray()?.List[lvNumber] : filterArray()?.List
                            }
                          />
                          <Owner
                            subMenu={subMenu}
                            inputValues={inputValues}
                            setInputValues={setInputValues}
                            previous={previous}
                            setOwnerMenu={setOwnerMenu}
                            ownerMenu={ownerMenu}
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </>
        ) : null}

        {gotResponse !== "true" ? (
          <div
            onClick={() => setPriceExpanded(!priceExpanded)}
            className="transition-all duration-200 ease-linear cursor-pointer absolute z-40 h-full w-full flex justify-center items-center bg-sky-100 overflow-hidden"
          >
            <Load />
          </div>
        ) : null}
      </motion.div>
    </AnimatePresence>
  ) : null;
}
