export const propertyListService = {
  getPropertyList: async (lat, lng, city, sessionIdToken) => {
    return await fetch(process.env.REACT_APP_LV_SERVICE + `${city}`, {
      headers: {
        latitude: lat,
        longitude: lng,
        sessionIdToken: sessionIdToken,
      },
    });
  },
};
