import { faBuilding, faFilePdf, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import BlueCity from "../../Images/blueCity.png";
import BlueHouse from "../../Images/blueHouse.png";
import BlueFlat from "../../Images/blueFlat.png";

export default function RHeader({ address, propList, blockOfFlats }) {
  const canvasRef = useRef(null);
  const [refresh, setRefresh] = useState(false);

  // console.log("address = ", address);

  const trimFullAddress = () => {
    return address.substring(0, address.lastIndexOf(","));
  };
  const trimAddress = () => {
    return address.substring(0, address.indexOf(","));
  };
  useEffect(() => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas?.getContext("2d", { willReadFrequently: true });
      canvas.width = img?.width ? img?.width : 0;
      canvas.height = img?.height ? img?.height : 0;
      context.drawImage(img, 0, 0, img?.width, img?.height);
      const imageData = context.getImageData(0, 0, img?.width, img?.height);
      const data = imageData?.data;
      let r = 0,
        g = 0,
        b = 0;
      const pixelCount = img?.width * img?.height;
      for (let i = 0; i < data?.length; i += 4) {
        r += data[i];
        g += data[i + 1];
        b += data[i + 2];
      }
      r = Math.round(r / pixelCount);
      g = Math.round(g / pixelCount);
      b = Math.round(b / pixelCount);
      // console.log("r = ", r, " g = ", g, " b = ", b);
      // console.log("propList = ", propList);
      if (r >= 220 && r <= 230 && g >= 220 && g <= 230 && b >= 220 && b <= 230) {
        if (propList?.List?.table_stavby?.druh_stavby?.includes("10")) {
          propList.img = BlueHouse;
          setRefresh(!refresh);
          return;
        }
        if (
          propList?.List?.table_stavby?.druh_stavby?.includes("9") ||
          propList?.List?.table_stavby?.druh_stavby?.includes("22")
        ) {
          propList.img = BlueFlat;
          setRefresh(!refresh);
          return;
        }
        propList.img = BlueCity;
        // console.log("changed propList = ", propList);
        setRefresh(!refresh);
      }
    };
    img.src = propList.img;
  });

  // console.log("propList = ", propList);

  const icon = () => {
    if (propList?.List === undefined) return;
    else if (propList?.List?.hasOwnProperty("byt")) return <FontAwesomeIcon icon={faBuilding} className="mr-1" />;
    else return <FontAwesomeIcon icon={faHouse} className="mr-1" />;
  };

  const title = () => {
    const type = propList?.List?.hasOwnProperty("byt") ? "Byt: " : propList?.List === undefined ? "" : "Rodinný dom";
    const entranceTitle = blockOfFlats.entrance !== null ? "vchod " + blockOfFlats.entrance : "";
    const floorTitle = blockOfFlats.floor !== null ? ", poschodie č. " + blockOfFlats.floor : "";
    const flatTitle = blockOfFlats.flat !== null ? ", byt č. " + blockOfFlats.flat : "";
    return `${type} ${entranceTitle} ${floorTitle} ${flatTitle}`;
  };

  // console.log("propList = ", propList);

  //border-b border-opacity-50 border-b-sky-500
  return (
    <div className="w-full h-auto flex flex-row px-2">
      <div className="flex flex-col w-11/12">
        <div className="text-lg">{trimAddress()}</div>
        <div>{trimFullAddress()}</div>
        <canvas ref={canvasRef} style={{ display: "none" }} />
        <div className="w-full h-full text-start overflow-hidden truncate">
          {icon()}
          {title()}
        </div>
        <img src={propList?.img} alt="" className="w-44 h-24 rounded-xl object-cover mt-1" />
      </div>
      <div
        onClick={() => {
          window.open(
            `https://kataster.skgeodesy.sk/Portal45/api/Bo/GeneratePrfPublic?prfNumber=${propList?.List?.cislo_listu_vlastnictva}&cadastralUnitCode=${propList?.List?.nazov_katastralneho_uzemia}&outputType=pdf`,
            "_blank",
          );
        }}
        className="flex w-1/12 justify-end items-start pt-2 cursor-pointer"
      >
        <FontAwesomeIcon icon={faFilePdf} />
      </div>
    </div>
  );
}
