import { saveAddressService } from "../../../services/saveAddressService";
import { averagePriceService } from "../../../services/averagePriceService";

export default function SelectFlat({
  filteredArray,
  blockOfFlats,
  setBlockOfFlats,
  addressService,
  avgPrice,
  setHistoryItems,
  setAvgPrice,
  avgPriceRef,
  municipalitiesRef,
  setPrepareHistory,
  prepareHistory,
}) {
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr?.map((item) => [item[key], item])).values()];
  };
  const arrFlat = getUniqueListBy(filteredArray?.List?.byt, "cislo_bytu");

  var newHistory = {};

  const setNewHistory = (data, value) => {
    prepareHistory = {
      ...prepareHistory,
      data: {
        ...prepareHistory.data,
        List: {
          ...prepareHistory.data.List,
          byt: [value],
        },
      },
    };

    newHistory.response = prepareHistory?.data;
    newHistory.location = prepareHistory?.location;
    newHistory.number = prepareHistory?.number;
    newHistory.avgPrice = data;
    newHistory.latAndLng = prepareHistory?.latLng;
    newHistory.psc = prepareHistory?.psc;
    newHistory.lvNum = prepareHistory?.lvNum;
    newHistory.flat = value?.cislo_bytu;
    newHistory.entrance = value?.vchod__cislo_;
    newHistory.floor = value?.poschodie?.trim();
    // newHistory.street

    if (localStorage.history) {
      var history = JSON.parse(localStorage.getItem("history"));
    } else {
      history = [];
    }

    newHistory.index = history.length;

    if (prepareHistory.data.hasOwnProperty("List")) {
      history?.reverse().push(newHistory);
      setHistoryItems([...history].reverse().slice(0, 10));
      localStorage.setItem("history", JSON.stringify([...history].reverse().slice(0, 10)));
    }
  };

  return filteredArray?.List?.hasOwnProperty("byt") ? (
    blockOfFlats.floor !== null && blockOfFlats.flat === null ? (
      <div className="w-full flex flex-col justify-center items-center">
        <div>Zvoľ byt</div>
        {arrFlat?.map((value, index) => {
          return (
            <div
              onClick={() => {
                setBlockOfFlats({ ...blockOfFlats, flat: Number(value?.cislo_bytu) });
                // console.log("municipalitiesRef = ", municipalitiesRef.current);
                averagePriceService
                  .getAveragePrice(
                    9,
                    "",
                    value?.podiel_priestoru_na_spolocnych_castiach_a_.split("/")[0] / 100,
                    "",
                    municipalitiesRef.current,
                  )
                  .then((response) => response.json())
                  .then((data) => {
                    avgPriceRef.current = data;
                    setAvgPrice(data);
                    setNewHistory(data, value);
                  });

                const user = JSON.parse(localStorage.getItem("userData"));
                const charsToEncode = /[\u007f-\uffff]/g;
                saveAddressService.setSaveAddressService(
                  user?.m,
                  addressService.streetName,
                  addressService.regNumber,
                  addressService.addressCurr,
                  addressService.psc,
                  addressService.country,
                  addressService.latitude,
                  addressService.longitude,
                  JSON.stringify(avgPrice).replace(charsToEncode, function (c) {
                    return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
                  }),
                  JSON.stringify(filteredArray).replace(charsToEncode, function (c) {
                    return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
                  }),
                  value?.cislo_bytu,
                  blockOfFlats.entrance,
                  "0",
                  filteredArray?.List?.cislo_listu_vlastnictva,
                );
                // const historyArray = JSON.parse(localStorage.getItem("history"));
                // const history = historyArray !== null ? historyArray[0] : [];
              }}
              key={index}
              className="h-10 border-2 border-gray-300 w-[78%] rounded-lg mb-1 flex items-center justify-center cursor-pointer"
            >
              <div>{value?.cislo_bytu}</div>
            </div>
          );
        })}
      </div>
    ) : null
  ) : null;
}
